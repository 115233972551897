import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import translate from "../utils/translation"
import styles from "./video.module.scss"


class VideoTemplate extends React.Component {

  render() {
    const post = this.props.data.markdownRemark
    const videoRoot = this.props.data.site.siteMetadata.videoRoot
    const lang = post.fields.slug.split('/')[1]
    const { parent } = this.props.pageContext
    const siteTitle = translate('site.name', lang)
    const headerSubTitle = parent ? parent.frontmatter.title : translate('site.description', lang)
    const backPath = parent ? parent.fields.slug : `/${lang}/`

    return (
      <Layout location={this.props.location} title={siteTitle} subTitle={headerSubTitle} slug={post.fields.slug}>
        <SEO
          title={post.frontmatter.title}
          description={post.frontmatter.description || post.excerpt}
          lang={lang}
          slug={post.fields.slug}
        />
        <article className="main-content">
          <div className={styles.container}>
            {
              post.frontmatter.videos.map(
                (v, i) => {
                  console.log(post.frontmatter.videoCaptions[i])
                  return <div key={i} className={styles.video}>
                    <h1>{post.frontmatter.videoTitles[i]}</h1>
                    <h2>{post.frontmatter.videoDescriptions[i]}</h2>
                    <video controls controlsList="nodownload"
                      poster={`${videoRoot}/${v.substring(0, v.length - 4)}.jpg`}>
                      <source src={`${videoRoot}/${v}`} type="video/mp4"></source>
                    </video>
                    <p className={styles.caption}>{post.frontmatter.videoCaptions[i]}</p>
                  </div>
                }
              )
            }
            {
              parent && (
                <div className={styles.control}>
                  <Link rel="back"
                    to={backPath}
                    style={{
                      boxShadow: `none`,
                      textDecoration: `none`,
                      color: `inherit`,
                    }}>
                    <button>Back</button>
                  </Link>
                </div>
              )
            }
          </div>
        </article>
      </Layout>
    )
  }
}

export default VideoTemplate

export const pageQuery = graphql`
  query VideoBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        videoRoot
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        videos
        videoTitles
        videoDescriptions
        videoCaptions
      }
    }
  }
`
